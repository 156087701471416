<template>
  <div class="bg-scanMain pt-20 pb-12 lg:pt-28">
    <Container>
      <div class="mb-4 ml-4">
        {{ t('locked_tokens') }}
      </div>
      <Loading v-if="loading" />
      <div v-else class="overflow-x-auto scrollbar">
        <div class="rounded-lg min-w-1024px overflow-hidden text-sm">
          <div class="bg-everWhite6 p-4 flex flex-row items-center">
            <div class="w-1/5">
              {{ t('token') }}
            </div>
            <div class="w-1/5">
              {{ t('source_chain') }}
            </div>
            <div class="w-1/5">
              {{ t('price') }}
            </div>
            <div class="w-1/5">
              {{ t('locked_volume') }}
            </div>
            <div class="w-1/5">
              {{ t('locked_market_cap') }}
            </div>
            <div class="w-1/5">
              {{ t('holders') }}
            </div>
            <div class="" :style="locale === 'en' || locale === 'fr' ? 'min-width:117px' : 'min-width:95px'">
              {{ t('more') }}
            </div>
          </div>
          <div
            v-for="(token, index) in tokenListInfo"
            :key="index"
            class="box-border p-4 flex flex-row items-center"
            :class="index === tokenListInfo.length-1 ? '' : 'border-b border-black-500 border-solid'">
            <router-link class="w-1/5 flex flex-row items-center text-everPurple10" :to="`/token/${token.tag}`">
              <TokenLogo class="w-4 h-4 mr-1" :symbol="token.symbol" /> {{ token.symbol }}
            </router-link>
            <div class="pl-0.5 w-1/5 break-all">
              {{ token.chainType }}
            </div>
            <div class="pl-0.5 w-1/5 break-all">
              <!-- ${{ formatInfo(token.price, 4) }} -->
              {{ token.price === '0' ? '-.-' : `$${formatInfo(token.price, 4)}` }}
            </div>
            <div class="pl-0.5 w-1/5 whitespace-nowrap">
              {{ formatInfo(token.totalSupplyFormat,2) }}
            </div>
            <div class="pl-0.5 w-1/5 whitespace-nowrap">
              ${{ formatInfo(token.totalSupplyPrice,2) }}
            </div>
            <div class="pl-0.5 w-1/5">
              {{ token.holderNum }}
            </div>
            <div class="whitespace-nowrap pl-0.5 text-scanClick hover:text-scanHoverColor" :style="locale === 'en' || locale === 'fr' ? 'min-width:117px' : 'min-width:95px'">
              <MoreInfo v-if="Object.values(token.viewUrl).filter(Boolean).length > 1">
                <div
                  v-for="(item, key, indexes ) in token.viewUrl"
                  :key="indexes">
                  <a
                    v-if="item"
                    class="block"
                    :href="item"
                    target="_blank">{{ t(key) }}</a>
                </div>
                <template #info>
                  <div>
                    {{ t('viewable_number_chains',{number:Object.values(token.viewUrl).filter(Boolean).length}) }}
                  </div>
                </template>
              </MoreInfo>
              <div v-else>
                <div
                  v-for="(item, key, indexes ) in token.viewUrl"
                  :key="indexes">
                  <a
                    v-if="item"
                    class="block"
                    :href="item"
                    target="_blank">{{ t(key) }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        :loading="loading"
        :current-page="currentPage"
        :has-next-page="isNextPage"
        @jump="handlePageChange" />
    </Container>
  </div>
</template>

<script lang="ts">
import { defineComponent, onActivated, computed, ref, Ref, onMounted } from 'vue'
import Container from '@/components/Container.vue'
import { getTokenListForScan } from '@/libs/api'
import { fromDecimalToUnit } from '@/libs/everpay-js/utils/util'
import { useStore } from '@/store'
import { getExplorerUrl, formatMoney, handleErrorMsg, formatChainTypeDisplay } from '@/libs/utils'
import { ChainType } from '@/libs/everpay-js'
import Loading from '@/components/common/Loading.vue'
import { useI18n } from 'vue-i18n'
import TokenLogo from '@/components/TokenLogo.vue'
import Pagination from '@/components/common/Pagination.vue'
import { isProd } from '@/constants'
import MoreInfo from '@/components/common/MoreInfo.vue'
import { TokenResult } from '@/pages/interface/index'
export default defineComponent({
  components: { Container, Loading, TokenLogo, Pagination, MoreInfo },
  setup () {
    const store = useStore()
    const tokenListInfo:Ref<any[]> = ref([])
    const { t, locale } = useI18n()
    const loading = ref(false)
    const currentPage = ref(1)
    const pageCacheIndex = ref(0)
    const pageCachelist = ref<number[]>([])
    const isNextPage = ref(false)
    const pageId = ref(0)
    const lockers = computed(() => store.state.lockers)
    const currencyPrices = computed(() => store.state.currencyPrices)
    const handlePageChange = (page:number) => {
      currentPage.value = page
      pageCacheIndex.value = currentPage.value - 1
      getTokenList()
    }
    const formatInfo = (amount: string, place: number) => {
      const [firstamount, lastamount] = amount.split('.')
      if (lastamount) {
        if (lastamount.length > place) {
          const price = firstamount + '.' + lastamount.slice(0, place)
          return formatMoney(price)
        }
      }
      return formatMoney(amount)
    }
    const isCrossChainSupported = (token: TokenResult, chainType:ChainType) => {
      return Object.keys(token.targetChains).includes(chainType) && Object.keys(lockers.value).includes(chainType)
    }
    const getTokenIdByChainType = (token: TokenResult, chainType:ChainType) => {
      const crossChainInfo = token.targetChains[chainType]
      return crossChainInfo.targetTokenId
    }
    const getViewUrl = (token: TokenResult) => {
      let viewArweaveUrl = ''
      let viewEthereumUrl = ''
      let viewMoonUrl = ''
      let viewConfluxUrl = ''
      let viewBnbUrl = ''
      let viewPlatonUrl = ''
      let viewMapoUrl = ''
      let viewAosTestUrl = ''
      let viewPsnTestUrl = ''
      const isEthereum = isCrossChainSupported(token, ChainType.ethereum)
      const isArweave = isCrossChainSupported(token, ChainType.arweave)
      const isMoon = isCrossChainSupported(token, ChainType.moon)
      const isConflux = isCrossChainSupported(token, ChainType.conflux)
      const isBnb = isCrossChainSupported(token, ChainType.bsc)
      const isPlaton = isCrossChainSupported(token, ChainType.platon)
      const isMapo = isCrossChainSupported(token, ChainType.mapo)
      const isAostest = isCrossChainSupported(token, ChainType.aostest)
      const isPsntest = isCrossChainSupported(token, ChainType.psntest)
      if (isEthereum) {
        const isEthSymbol = token.symbol === 'ETH'
        viewEthereumUrl = getExplorerUrl({
          type: isEthSymbol ? 'address' : 'token',
          value: isEthSymbol ? lockers.value.ethereum.address as string : getTokenIdByChainType(token, ChainType.ethereum),
          apply: isEthSymbol ? '' : lockers.value.ethereum.address as string
        }, ChainType.ethereum)
      }
      if (isArweave) {
        const isArSymbol = token.symbol === 'AR'
        viewArweaveUrl = getExplorerUrl({
          type: 'address',
          value: isArSymbol ? lockers.value.arweave.address as string : getTokenIdByChainType(token, ChainType.arweave)
        }, ChainType.arweave)
      }
      if (isMoon) {
        const isMoonSymbol = isProd ? token.symbol === 'GLMR' : token.symbol === 'DEV'
        viewMoonUrl = getExplorerUrl({
          type: 'address',
          value: isMoonSymbol ? lockers.value.moon.address as string : getTokenIdByChainType(token, ChainType.moon)
        }, ChainType.moon)
      }
      if (isConflux) {
        viewConfluxUrl = getExplorerUrl({
          type: 'address',
          value: lockers.value.conflux.address as string
        }, ChainType.conflux)
      }
      if (isBnb) {
        viewBnbUrl = getExplorerUrl({
          type: 'address',
          value: lockers.value.bsc.address as string
        }, ChainType.bsc)
      }
      if (isPlaton) {
        viewPlatonUrl = getExplorerUrl({
          type: 'address',
          value: lockers.value.platon.address as string
        }, ChainType.platon)
      }
      if (isMapo) {
        viewMapoUrl = getExplorerUrl({
          type: 'address',
          value: lockers.value.mapo.address as string
        }, ChainType.mapo)
      }
      if (isAostest) {
        viewAosTestUrl = `https://ao_marton.g8way.io/#/process/${token.tokenID}`
      }
      if (isPsntest) {
        viewPsnTestUrl = getExplorerUrl({
          type: 'address',
          value: lockers.value.psntest.address as string
        }, ChainType.psntest)
      }
      return {
        view_on_viewblock: viewArweaveUrl,
        view_on_etherscan: viewEthereumUrl,
        view_on_moonscan: viewMoonUrl,
        view_on_conflux: viewConfluxUrl,
        view_on_bnb: viewBnbUrl,
        view_on_platon: viewPlatonUrl,
        view_on_mapo: viewMapoUrl,
        view_on_aostest: viewAosTestUrl,
        view_on_psntest: viewPsnTestUrl
      }
    }
    const getTokenList = async () => {
      loading.value = true
      try {
        if (pageCachelist.value[pageCacheIndex.value] !== undefined) {
          pageId.value = pageCachelist.value[pageCacheIndex.value]
        }
        const { tokenList, hasNextPage } = await getTokenListForScan(pageId.value, 20)
        isNextPage.value = hasNextPage
        if (pageCachelist.value[pageCacheIndex.value] === undefined) {
          pageCachelist.value.push(pageId.value)
        }
        if (hasNextPage) {
          pageId.value = +tokenList[tokenList.length - 1].rankID
        }
        const result = tokenList.map((token) => {
          const price = token.price.toString()
          const totalSupplyFormat = fromDecimalToUnit(token.totalSupply, token.decimals)
          const totalSupplyPrice = token.volume.toString()
          const viewUrl = getViewUrl(token)
          return {
            chainType: formatChainTypeDisplay(token.chainType),
            id: token.rankID,
            symbol: token.symbol,
            holderNum: token.holderNum,
            price,
            totalSupplyFormat,
            totalSupplyPrice,
            viewUrl,
            tag: token.tag
          }
        })
        result.sort((a, b) => {
          return +b.totalSupplyPrice - +a.totalSupplyPrice
        })
        tokenListInfo.value = result
      } catch (e:any) {
        console.error(e)
        handleErrorMsg(e, t)
      } finally {
        loading.value = false
      }
    }
    onMounted(async () => {
      if (!currencyPrices.value || !currencyPrices.value.length) {
        await store.dispatch('updateCurrencyPricesAsnyc')
      }
    })
    onActivated(() => {
      currentPage.value = 1
      pageCachelist.value = []
      isNextPage.value = false
      pageId.value = 0
      pageCacheIndex.value = 0
      currentPage.value = 1
      getTokenList()
    })
    return {
      t,
      tokenListInfo,
      loading,
      formatMoney,
      locale,
      formatInfo,
      handlePageChange,
      currentPage,
      isNextPage
    }
  }
})
</script>
